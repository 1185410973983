
.landing-body{
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px); 
  min-height: 800px;  
}

.landing-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px;
  padding: 20px; 
}
.custom{
  font-size: 34px;
  font-weight: 400;
  font-family: 'Tienne', serif;
}
.need{
  font-size: 44px;
  font-weight: 400;
  font-family: 'Tienne', serif;
}
.landing-content p{
  font-size: 17px; 
  font-weight: 700;
}

.landing-input{
  font-size: 17px;  
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  outline: none;
  max-width: 600px;
  width: 70%;
}

.landing-content p strong{ 
  font-weight: 900 !important;
}

.landing-content h2{ 
  text-align: center;  
  padding: 20px;
  font-size: 35px;  
  font-family: 'Tienne', serif;
}

.landing-content h2 span{ 
  color: #0913FA;
}

.landing-content b{ 
  font-size: 16px;
  margin: 20px 0px;
}

.ado-vdo-lables{
  display: flex;
  justify-content: center;
  align-items: center; 
}

.ado-vdo-lables div{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column; 
  margin: 0px 40px; 
}

.ado-vdo-lables div .icon{
  color: #0913FA;
  font-size: 30px; 
}

.ado-vdo-lables div p{
  font-weight: 800; 
  margin-top: 5px;
}

.btn-groups{ 
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 100px 0 0 300px ;
}
 

.btn-groups button{
  background-color: #0913FA;
  color: #fff;
  padding: 15px 20px;
  font-weight: 700;
  border-radius: 5px;
  width: 150px;
  border: none;
}
 

.btn-groups button.btn-transparent{
  background-color: transparent; 
  color: black;
}
 

.social-icons{ 
  text-align: center;
  margin: 50px 0px; 
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%; 
  font-size: 30px;
  color: #0913FA;
}

.social-icons .icon{ 
  margin: 0px 10px;  
} 


 @media(max-width:360px){
  .landing-content{
    margin: 20px 0px;
  }
  .custom{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Tienne', serif;
    margin: 10px 0px;
  }
  .need{
    font-size: 15px;
    font-weight: 400;
    font-family: 'Tienne', serif;
    margin: 10px 0px;
  }
  .landing-input{
    width:312px;
height:48px;
  }
  .ado-vdo-lables{
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .ado-vdo-lables div p{
    font-weight: 600; 
    margin-top: 5px;
    font-size: 13px;
  }
  .btn-groups{ 
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 10px 0px;
  
  }
 } 