
.landing-body{
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px); 
  min-height: 800px;  
  max-height: 900px; 
}

.landing-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px;
  padding: 20px;
}



.landing-content span {
  text-align: center;
  font-size: 32px;
  padding: 20px;
  font-family: 'Tienne', serif;
}

.landing-content span span {
  color: #0913FA;
}

.three-lables {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.three-lables div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 3px solid black;
  height: 260px;
  width: 170px;
  margin: 0px 40px;
  border-radius: 20px;
}

.three-lables div img {
  width: 120px;
}

.three-lables div p {
  font-size: 18px;
  font-weight: 800;
}

.links {
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  color: black;


}


.social-icons {
  text-align: center;
  width: 100%;
  font-size: 30px;
  color: #0913FA;
}

.social-icons .icon {
  margin: 0px 10px;
}




/* this code for navbar landing header  */
.navbar-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 5% !important;
}

.navbar-div .logo h4 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.navbar-div .nav {
  display: flex;
}

.navbar-div .nav a {
  text-decoration: none;
  margin: 0px 20px;
  color: black;
  font-size: 17px;
  font-weight: 600;
}

.auth-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-btn button {
  border: none;
  background: transparent;
  font-size: 17px;
  font-weight: 600;
  padding: 5px 10px;
  margin: 0px 10px;
  border-radius: 5px;
}

.auth-btn button a {
  color: black;
  text-decoration: none;
}


.auth-btn button.signup-btn {
  border: none;
  background: transparent;
  font-size: 17px;
  font-weight: 600;
  background-color: #0913FA;
  padding: 5px 10px;
  margin: 0px 10px;
  color: white;
  border-radius: 5px;
}

.auth-btn button.signup-btn a {
  color: white;
}

@media(max-width:744px) {

  .three-lables {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px; 
  }

  .three-lables div {
    height: 210px;
    width: 210px;
  }

  .three-lables {
    position: relative;
  }

  .landing-content span {
    font-size: 20px;
  }

}